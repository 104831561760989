/**
=========================================================
* Manual Listing System - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const buttonBase = {
  defaultProps: {
    disableRipple: false,
  },
};

export default buttonBase;
