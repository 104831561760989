/**
=========================================================
* Manual Listing System - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDPagination from 'components/MDPagination';
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { getDROP } from "features/slices";
import moment from "moment";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";

function Tables() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.data || []);
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const [currentPage, setCurrentPage] = useState(1);


  const handleChangePage = async (newPage) => {
    await dispatch(
      getTiktok({
        startDate: moment(startDate).startOf("day").format("x"),
        endDate: moment(endDate).endOf("day").format("x"),
        currentPage: newPage,
      })
    );
    setCurrentPage(newPage);
  };

  useEffect(() => {
    dispatch(
      getTiktok({
        startDate: moment(startDate).startOf("day").format("x"),
        endDate: moment(endDate).endOf("day").format("x"), currentPage
      })
    );
  }, [startDate, endDate]);

  const renderPagination = Array.from({ length: data.totalPage }, (_, index) => {
    const page = index + 1;
    const isNearCurrentPage = Math.abs(currentPage - page) <= 2;

    if (!isNearCurrentPage) {
      return null;
    }

    return (
      <MDPagination
        item
        key={page}
        onClick={() => handleChangePage(page)}
        active={currentPage === page}
      >
        {page}
      </MDPagination>
    );
  });

  function getFirstWords(text) {
    if (text.length > 35) {
      return text.substring(0, 35) + "...";
    } else {
      return text;
    }
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", flexDirection: "row" }} >

                <TextField
                  className='inputText'
                  style={{ marginLeft: 5, height: 50, width: "40%" }}
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                ></TextField>
                <Button variant="contained" className='inputText' color="dark" style={{ marginLeft: 5 }} onClick={handleSearch}>
                  Search
                </Button>
              </MDBox> */}
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns: [
                      { Header: "id", accessor: "id", align: "left" },
                      { Header: "date", accessor: "date", align: "left" },
                      { Header: "user", accessor: "email", align: "left" },
                      { Header: "product name", accessor: "product_name", align: "left" },
                      { Header: "category", accessor: "category", align: "left" },
                      { Header: "Images", accessor: "main_image", align: "left" },
                    ],
                    rows: data.data && data.data.length > 0 ? [...data.data.map((items, index) => ({
                      id: <><MDBadge badgeContent={items.id} color={"info"} variant="gradient" size="sm" /></>,
                      date: <>{moment(items.createdAt).format("DD/MM")}</>,
                      email: <>
                        <MDBadge badgeContent={items.user ? items.user.replace("@gmail.com", "") : ""} color={"warning"} variant="gradient" size="sm" /></>,
                      product_name: <>{getFirstWords(items.title)}</>,
                      category: <>{items.category}</>,
                      main_image: <><img src={items.newurl} style={{ width: 100, height: 100 }}></img></>,


                    })
                    )] : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <MDBox
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  padding="5px"
                >
                  <MDPagination
                    variant="gradient"
                    color="info"
                  >
                    {currentPage > 1 && data.totalPage > 1 && (
                      <MDPagination item onClick={() => handleChangePage(currentPage - 1)}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                      </MDPagination>
                    )}
                    {renderPagination}
                    {currentPage < data.totalPage && (
                      <MDPagination item onClick={() => handleChangePage(currentPage + 1)}>
                        <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                      </MDPagination>
                    )}
                  </MDPagination>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>


      <Footer />
    </DashboardLayout >
  );
}

export default Tables;
