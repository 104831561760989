// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* loading-screen.css */
.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Màu nền mờ */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Đảm bảo nó nằm trên tất cả các phần khác */
  }
  
  .loading-spinner {
    text-align: center;
    font-size: 24px;
    color: #fff; /* Màu chữ */
    padding: 20px;
    background-color: #333; /* Màu nền của khung loading */
    border-radius: 10px; /* Bo tròn khung loading */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Hiệu ứng bóng đổ */
  }
  
  /* Hiệu ứng quay vòng (circular loading) */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-spinner::after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 4px solid #fff; /* Màu và độ dày của đường viền */
    border-top: 4px solid transparent;
    border-radius: 50%; /* Biểu tượng tròn */
    animation: spin 1s linear infinite; /* Hiệu ứng quay vòng */
  }
  `, "",{"version":3,"sources":["webpack://./src/layouts/handlist/loading-screen.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,eAAe;IACrD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,6CAA6C;EAC9D;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,WAAW,EAAE,YAAY;IACzB,aAAa;IACb,sBAAsB,EAAE,8BAA8B;IACtD,mBAAmB,EAAE,0BAA0B;IAC/C,uCAAuC,EAAE,qBAAqB;EAChE;;EAEA,0CAA0C;EAC1C;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;EAEA;IACE,WAAW;IACX,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,sBAAsB,EAAE,iCAAiC;IACzD,iCAAiC;IACjC,kBAAkB,EAAE,oBAAoB;IACxC,kCAAkC,EAAE,uBAAuB;EAC7D","sourcesContent":["/* loading-screen.css */\n.loading-screen {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7); /* Màu nền mờ */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999; /* Đảm bảo nó nằm trên tất cả các phần khác */\n  }\n  \n  .loading-spinner {\n    text-align: center;\n    font-size: 24px;\n    color: #fff; /* Màu chữ */\n    padding: 20px;\n    background-color: #333; /* Màu nền của khung loading */\n    border-radius: 10px; /* Bo tròn khung loading */\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Hiệu ứng bóng đổ */\n  }\n  \n  /* Hiệu ứng quay vòng (circular loading) */\n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n  .loading-spinner::after {\n    content: \"\";\n    display: inline-block;\n    width: 40px;\n    height: 40px;\n    border: 4px solid #fff; /* Màu và độ dày của đường viền */\n    border-top: 4px solid transparent;\n    border-radius: 50%; /* Biểu tượng tròn */\n    animation: spin 1s linear infinite; /* Hiệu ứng quay vòng */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
