/**
=========================================================
* Manual Listing System - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Manual Listing System base styles
import colors from "assets/theme/base/colors";

const { text } = colors;

const formLabel = {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};

export default formLabel;
