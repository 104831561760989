// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
a {
    cursor: pointer
}

.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-datepicker {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-datepicker:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 8px rgba(128, 189, 255, 0.6);
}

.datePicker {
    display: flex;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,4CAA4C;AAChD;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["\na {\n    cursor: pointer\n}\n\n.form-group {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.custom-datepicker {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ced4da;\n    border-radius: 5px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.custom-datepicker:focus {\n    outline: none;\n    border-color: #80bdff;\n    box-shadow: 0 0 8px rgba(128, 189, 255, 0.6);\n}\n\n.datePicker {\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
