/**
=========================================================
* Manual Listing System - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Box, FormControl, MenuItem, Select, TextField, Autocomplete } from '@mui/material';
import Card from "@mui/material/Card";
import { encode } from 'html-entities';

// Manual Listing System components
import MDBox from "components/MDBox";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// Manual Listing System example components
import { styled } from '@mui/system';
import MDBadge from 'components/MDBadge';
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from 'examples/Tables/DataTable';
import { createDROP, deleteDROP, getDROP } from 'features/slices';
import moment from 'moment';
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-notifications/lib/notifications.css";
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import header from './constants';
import cate from './constants1';
import LoadingScreen from './Loading';
import { updateDrop } from 'features/slices';
const StyledFormControl = styled(FormControl)({
  width: 225,
});
function replaceUTF8(chuoi) {
  if (chuoi) {
    return encode(chuoi).replace(/\n/g, '<br>');
  } else {
    return chuoi
  }
}
function HandleList() {
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.user.isLoading);
  const email = useSelector((state) => state.user.email);
  const data = useSelector((state) => state.user.data);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [mainImages, setMainImages] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [brand, setBrand] = useState("");
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [material, setMaterial] = useState("");
  const [gtin, setGTIN] = useState("");
  const [mpn, setMPN] = useState("");
  const [type, setType] = useState('simple');
  const [varName1, setVarName1] = useState('');
  const [varName2, setVarName2] = useState('');
  const [varName3, setVarName3] = useState('');
  const [varValue1, setVarValue1] = useState('');
  const [varValue2, setVarValue2] = useState('');
  const [varValue3, setVarValue3] = useState('');
  const [variable, setVariable] = useState([]);
  const [show, setShow] = useState(false);
  const [auto, setAuto] = useState(true);
  const [manual, setManual] = useState([]);

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleChangeManual1 = (index, event) => {
    const newValue = event.target.value;
    const updatedManual = [...manual];
    updatedManual[index][0] = newValue;
    setManual(updatedManual);
  };
  const handleChangeManual2 = (index, event) => {
    const newValue = event.target.value;
    const updatedManual = [...manual];
    updatedManual[index][1] = newValue;
    setManual(updatedManual);
  };
  const handleChangeManual3 = (index, event) => {
    const newValue = event.target.value;
    const updatedManual = [...manual];
    updatedManual[index][2] = newValue;
    setManual(updatedManual);
  };
  const handleChangeManual = (index, value) => {
    const newData = [...manual];
    newData[index][4] = value;
    setManual(newData);
  };
  const handleChangeManualGTIN = (index, value) => {
    const newData = [...manual];
    newData[index][5] = value;
    setManual(newData);
  };
  const handleChangeManualMPN = (index, value) => {
    const newData = [...manual];
    newData[index][6] = value;
    setManual(newData);
  };
  const handleChangeImageManual = (index, value) => {
    const newData = [...manual];
    newData[index][3] = value;
    setManual(newData);
  };

  const handleAdd = async () => {
    const newDes = replaceUTF8(description)
    setShow(true)
    let newVariable = []
    if (!auto && type == "variable") {
      for (let _manual of manual) {
        if (varName1 && varValue1) {
          if (varName2 && varValue1) {
            if (varName3 && varName3) {
              if (_manual[0] == "any" && _manual[1] == "any" && _manual[2] == "any") {
                for (let _varValue1 of varValue1.split(",")) {
                  for (let _varValue2 of varValue2.split(",")) {
                    for (let _varValue3 of varValue3.split(",")) {
                      newVariable.push([_varValue1, _varValue2, _varValue3, _manual[3], _manual[4], _manual[5], _manual[6]])
                    }
                  }
                }
              } else if (_manual[0] == "any" && _manual[1] == "any" && _manual[2] != "any") {
                for (let _varValue1 of varValue1.split(",")) {
                  for (let _varValue2 of varValue2.split(",")) {
                    newVariable.push([_varValue1, _varValue2, _manual[2], _manual[3], _manual[4], _manual[5], _manual[6]])
                  }
                }
              } else if (_manual[0] == "any" && _manual[1] != "any" && _manual[2] == "any") {
                for (let _varValue1 of varValue1.split(",")) {
                  for (let _varValue3 of varValue3.split(",")) {
                    newVariable.push([_varValue1, _manual[1], _varValue3, _manual[3], _manual[4], _manual[5], _manual[6]])
                  }
                }
              } else if (_manual[0] == "any" && _manual[1] != "any" && _manual[2] != "any") {
                for (let _varValue1 of varValue1.split(",")) {
                  newVariable.push([_varValue1, _manual[1], _manual[2], _manual[3], _manual[4], _manual[5], _manual[6]])
                }
              } else if (_manual[0] != "any" && _manual[1] == "any" && _manual[2] == "any") {
                for (let _varValue1 of varValue1.split(",")) {
                  newVariable.push([_varValue1, _manual[1], _manual[2], _manual[3], _manual[4], _manual[5], _manual[6]])
                }
              }
              else if (_manual[0] != "any" && _manual[1] == "any" && _manual[2] != "any") {
                for (let _varValue2 of varValue2.split(",")) {
                  newVariable.push([_manual[0], _varValue2, _manual[2], _manual[3], _manual[4], _manual[5], _manual[6]])
                }
              }
              else if (_manual[0] != "any" && _manual[1] != "any" && _manual[2] == "any") {
                for (let _varValue3 of varValue3.split(",")) {
                  newVariable.push([_manual[0], _manual[1], _varValue3, _manual[3], _manual[4], _manual[5], _manual[6]])
                }
              }
              else {
                newVariable.push([_manual[0], _manual[1], _manual[2], _manual[3], _manual[4], _manual[5], _manual[6]])
              }
            } else {
              if (_manual[0] == "any" && _manual[1] == "any") {
                for (let _varValue1 of varValue1.split(",")) {
                  for (let _varValue2 of varValue2.split(",")) {
                    newVariable.push([_varValue1, _varValue2, "", _manual[3], _manual[4], _manual[5], _manual[6]])
                  }
                }
              } else if (_manual[0] == "any" && _manual[1] != "any") {
                for (let _varValue1 of varValue1.split(",")) {
                  newVariable.push([_varValue1, _manual[1], "", _manual[3], _manual[4], _manual[5], _manual[6]])
                }
              }
              else if (_manual[0] != "any" && _manual[1] == "any") {
                for (let _varValue2 of varValue2.split(",")) {
                  newVariable.push([_manual[0], _varValue2, "", _manual[3], _manual[4], _manual[5], _manual[6]])
                }
              }
              else {
                newVariable.push([_manual[0], _manual[1], "", _manual[3], _manual[4], _manual[5], _manual[6]])
              }
            }
          } else {
            if (_manual[0] == "any") {
              for (let _varValue1 of varValue1.split(",")) {
                newVariable.push([_varValue1, "", "", _manual[3], _manual[4], _manual[5], _manual[6]])
              }
            } else {
              newVariable.push([_manual[0], "", "", _manual[3], _manual[4], _manual[5], _manual[6]])
            }
          }
        } else {
          break
        }
      }
      setVariable(newVariable)
    }
    const res = await dispatch(createDROP({ user: email, title, description: newDes, price, category, sku: `SKU${moment().unix()}`, tag: tags, mainimage: mainImages, image1, image2, image3, brand, size, color, material, type, varname: [varName1, varName2, varValue3], varvalue: [varValue1, varValue2, varValue3], variable: !auto && type == "variable" ? newVariable : variable, gtin, mpn }))
    if (res.payload.status == 0) {
      await dispatch(getDROP({ user: email }))
    }
  }

  const handleDelete = (index) => {
    setVariable(variable.filter((_, i) => i !== index));
  };

  const handleDeleteData = async (id) => {
    const res = await dispatch(deleteDROP({ id }))
    if (res.payload.status == 0) {
      await dispatch(getDROP({ user: email }))
    }
  };

  const handleDeleteManual = async (index) => {
    setManual(manual.filter((_, i) => i !== index));
  };

  const handleExport = async (index) => {
    const res = await dispatch(updateDrop({ user: email }))
    if (res.payload?.status == 0) {
      let newData = []
      for (let _data of data) {
        let images = []
        if (mainImages) {
          images.push(mainImages)
        }
        if (image1) {
          images.push(image1)
        }
        if (image2) {
          images.push(image2)
        }
        if (image3) {
          images.push(image3)
        }
        for (let _images of _data.variable) {
          if (_images?.[3] && !images.includes(_images?.[3])) {
            images.push(_images?.[3])
          }
        }
        console.log(_data)
        if (_data.type == "simple") {
          newData.push([_data?.sku, "simple", _data?.sku, _data?.title, 1, 0, "visible", "", _data?.description, "", "", "taxable", "", "", 6, "", "", 0, 0, "", "", "", 1, "", "", _data.price, _data.category, _data.tag, "", "", "", "", "", "", "", "", "", "", 0, "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", _data?.gtin, _data?.gtin, _data?.mpn, _data?.brand, _data?.mainimage, _data?.mainimage, "", "synced", "sync-and-show", _data?.image1, _data?.image2, _data?.image3, _data?.brand, "new", _data?.size, "US", "regular", _data?.color, _data.material, "adult", "no", "no"])
        } else {
          newData.push([_data?.sku, "variable", _data?.sku, _data?.title, 1, 0, "visible", "", _data?.description, "", "", "taxable", "", "", 6, "", "", 0, 0, "", "", "", 1, "", "", "", _data.category, _data.tag, "", "", "", "", "", "", "", "", "", "", 0, _data?.varname?.[0] || "", _data?.varvalue?.[0] || "", _data?.varname?.[0] ? 1 : "", _data?.varname?.[0] ? 1 : "", _data?.varvalue?.[0] ? _data?.varvalue?.[0].split(",")[0] : "", _data?.varname?.[1] || "", _data?.varvalue?.[1] || "", _data?.varname?.[1] ? 1 : "", _data?.varname?.[1] ? 1 : "", _data?.varvalue?.[1] ? _data?.varvalue?.[1].split(",")[0] : "", _data?.varname?.[2] || "", _data?.varvalue?.[2] || "", _data?.varname?.[2] ? 1 : "", _data?.varname?.[2] ? 1 : "", _data?.varvalue?.[2] ? _data?.varvalue?.[2].split(",")[0] : "", "", "", _data?.gtin, "", "", _data?.brand, _data?.mainimage, _data?.mainimage, "", "synced", "sync-and-show", _data?.image1, _data?.image2, _data?.image3, _data?.brand, "new", _data?.size, "US", "regular", _data?.color, _data.material, "adult", "no", "no"])
          for (let [index, _variable] of _data?.variable.entries()) {
            newData.push([`${_data?.sku}-${index}`, "variation", `${_data?.sku}-${index}`, _data?.title, 1, 0, "visible", "", "", "", "", "taxable", "parent", "", 6, "", "", 0, 0, "", "", "", 1, "", "", _variable?.[4], "", "", "", "", "", "", _data?.sku, "", "", "", "", "", index + 1, _data?.varname?.[0] || "", _variable?.[0] || "", "", _data?.varname?.[0] ? 1 : "", "", _data?.varname?.[1] || "", _variable?.[1] || "", "", _data?.varname?.[1] ? 1 : "", "", _data?.varname?.[2] || "", _variable?.[2] || "", "", _data?.varname?.[2] ? 1 : "", "", "", "", "", _variable?.[5] ? _variable?.[5] : _data.gtin, _variable?.[6] ? _variable?.[6] : _data.mpn, "", _variable?.[3] ? _variable?.[3] : _data.mainimage, _variable?.[3] ? _variable?.[3] : _data.mainimage])
          }
        }
      }
      const ws = XLSX.utils.aoa_to_sheet([header, ...newData]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `Drop_${moment().format("DD/MM")}_${moment().unix()}.csv`);
      await dispatch(getDROP({ user: email }))
    }
  };

  const handleChange = (index, value) => {
    const newData = [...variable];
    newData[index][4] = value;
    setVariable(newData);
  };
  const handleChangeGTIN = (index, value) => {
    const newData = [...variable];
    newData[index][5] = value;
    setVariable(newData);
  };
  const handleChangeMPN = (index, value) => {
    const newData = [...variable];
    newData[index][6] = value;
    setVariable(newData);
  };
  const handleChangeImage = (index, value) => {
    const newData = [...variable];
    newData[index][3] = value;
    setVariable(newData);
  };

  const handleCreateVariableManual = async () => {
    setAuto(false)
    if (varName1 && varValue1) {
      setManual([...manual, ["any", "any", "any", "", "", "", ""]])
    }
  }

  const handleCreateVariable = async () => {
    setAuto(true)
    let variation = []
    for (let _varValue1 of varValue1.split(",")) {
      if (varValue2 && varValue2.split(",").length > 0) {
        for (let _varValue2 of varValue2.split(",")) {
          if (varValue3 && varValue3.split(",").length > 0) {
            for (let _varValue3 of varValue3.split(",")) {
              variation.push([_varValue1, _varValue2, _varValue3, "", "", "", ""])
            }
          } else {
            variation.push([_varValue1, _varValue2, "", "", "", "", ""])
          }
        }
      } else {
        variation.push([_varValue1, "", "", "", "", "", ""])
      }
    }
    setVariable(variation)
    console.log(variation)
  }

  useEffect(() => {
    dispatch(getDROP({ user: email }))
  }, []);

  return (
    <DashboardLayout>
      {isLoading && <LoadingScreen />}
      <DashboardNavbar />
      <MDBox pt={3} pb={3} style={{ textAlign: "center" }}>
        <Card style={{ textAlign: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 1 }}>
            <TextField
              label="Title"
              sx={{
                marginBottom: '16px', marginLeft: 1, width: "50%", marginRight: 1,
                '& .MuiOutlinedInput-root': !title ? {
                  '& fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                } : {},
              }}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <TextField
              label="Price"
              sx={{
                marginBottom: '16px', width: "10%", marginRight: 1,
                '& .MuiOutlinedInput-root': !price ? {
                  '& fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                } : {},
              }}
              value={price}
              onChange={e => setPrice(e.target.value)}
            />
            <Autocomplete
              sx={{
                width: "30%", marginRight: 1,
                '& .MuiOutlinedInput-root': !category ? {
                  '& fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                } : {},
              }}
              options={cate}
              onChange={(event, newValue) => {
                setCategory(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
            />
            <TextField
              label="Tag"
              sx={{ marginBottom: '16px', width: "10%", marginRight: 1 }}
              value={tags}
              onChange={e => setTags(e.target.value)}
            />
          </Box>
          <TextField
            label="Description"
            multiline
            rows={2}
            sx={{
              marginBottom: '16px', marginLeft: 1, marginRight: 1,
              '& .MuiOutlinedInput-root': !description ? {
                '& fieldset': {
                  borderColor: 'red',
                },
                '&:hover fieldset': {
                  borderColor: 'red',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'red',
                },
              } : {},
            }}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Main Image"
              sx={{
                marginBottom: '16px', width: "100%", marginLeft: 1,
                '& .MuiOutlinedInput-root': !mainImages ? {
                  '& fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                } : {},
              }}
              value={mainImages}
              onChange={(e) => setMainImages(e.target.value)}
            />
            <TextField
              label="Image 1"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1 }}
              value={image1}
              onChange={(e) => setImage1(e.target.value)}
            />
            <TextField
              label="Image 2"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }}
              value={image2}
              onChange={(e) => setImage2(e.target.value)}
            />
            <TextField
              label="Image 3"
              sx={{ marginBottom: '16px', width: "100%", marginRight: 1 }}
              value={image3}
              onChange={(e) => setImage3(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TextField
              label="Brand Mặc Định"
              sx={{
                marginBottom: '16px', width: "100%", marginLeft: 1,
                '& .MuiOutlinedInput-root': !brand ? {
                  '& fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                } : {},
              }}
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
            <TextField
              label="GTIN"
              sx={{
                marginBottom: '16px', width: "100%", marginLeft: 1,
                '& .MuiOutlinedInput-root': gtin || mpn ? {} : {
                  '& fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                }
              }}
              value={gtin}
              onChange={(e) => setGTIN(e.target.value)}
            />
            <TextField
              label="MPN"
              sx={{
                marginBottom: '16px', width: "100%", marginLeft: 1,
                '& .MuiOutlinedInput-root': gtin || mpn ? {} : {
                  '& fieldset': {
                    borderColor: 'red',
                  },
                  '&:hover fieldset': {
                    borderColor: 'red',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'red',
                  },
                }
              }}
              value={mpn}
              onChange={(e) => setMPN(e.target.value)}
            />
            <TextField
              label="Size (Nếu có)"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1 }}
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />
            <TextField
              label="Color (Nếu có)"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1 }}
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
            <TextField
              label="Material (Nếu có)"
              sx={{ marginBottom: '16px', width: "100%", marginLeft: 1 }}
              value={material}
              onChange={(e) => setMaterial(e.target.value)}
            />

            <StyledFormControl sx={{ marginBottom: '16px', width: "100%", marginLeft: 1, marginRight: 1 }} >
              <Select
                sx={{ height: '45px', background: "linear-gradient(195deg, #42424a, #191919)", fontWeight: "bold" }}
                value={type}
                onChange={handleChangeType}
              >
                <MenuItem value="simple">Simple</MenuItem>
                <MenuItem value="variable">Variable</MenuItem>
              </Select>
            </StyledFormControl>
          </Box>

          {type == "variable" ?
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                <TextField
                  label="Biến Thể 1"
                  sx={{ marginBottom: '16px', width: "7%", marginLeft: 1 }}
                  value={varName1}
                  onChange={(e) => setVarName1(e.target.value)}
                />
                <TextField
                  label="Giá Trị BT1 Cách Nhau Bằng ,"
                  sx={{ marginBottom: '16px', width: "23%", marginLeft: 1 }}
                  value={varValue1}
                  onChange={(e) => setVarValue1(e.target.value)}
                />
                <TextField
                  label="Biến Thể 2"
                  sx={{ marginBottom: '16px', width: "7%", marginLeft: 1 }}
                  value={varName2}
                  onChange={(e) => setVarName2(e.target.value)}
                />
                <TextField
                  label="Giá Trị BT2 Cách Nhau Bằng ,"
                  sx={{ marginBottom: '16px', width: "23%", marginLeft: 1 }}
                  value={varValue2}
                  onChange={(e) => setVarValue2(e.target.value)}
                />
                <TextField
                  label="Biến Thể 3"
                  sx={{ marginBottom: '16px', width: "7%", marginLeft: 1 }}
                  value={varName3}
                  onChange={(e) => setVarName3(e.target.value)}
                />
                <TextField
                  label="Giá Trị BT3 Cách Nhau Bằng ,"
                  sx={{ marginBottom: '16px', width: "23%", marginLeft: 1 }}
                  value={varValue3}
                  onChange={(e) => setVarValue3(e.target.value)}
                />

                <MDButton variant="contained" color="info" onClick={handleCreateVariable} component="span" sx={{ marginRight: 1, marginLeft: 1, width: "10%", height: "45px" }}>
                  Gen biến thể
                </MDButton>
                <MDButton variant="contained" color="info" onClick={handleCreateVariableManual} component="span" sx={{ marginRight: 1, marginLeft: 1, width: "10%", height: "45px" }}>
                  Tạo thủ công
                </MDButton>
              </Box>

              {auto ? <DataTable
                table={{
                  columns: [
                    { Header: "id", accessor: "id", align: "left" },
                    { Header: `${varName1 || "Null"}`, accessor: "value1", align: "left" },
                    { Header: `${varName2 || "Null"}`, accessor: "value2", align: "left" },
                    { Header: `${varName3 || "Null"} `, accessor: "value3", align: "left" },
                    { Header: `image link`, accessor: "image", align: "left" },
                    { Header: `show`, accessor: "show", align: "left" },
                    { Header: "price", accessor: "price", align: "left" },
                    { Header: "gtin", accessor: "gtin", align: "left" },
                    { Header: "mpn", accessor: "mpn", align: "left" },
                    { Header: "delete", accessor: "delete", align: "left" },
                  ],
                  rows: variable && variable.length > 0 ? [...variable.map((items, index) => ({
                    id: <><MDBadge badgeContent={index + 1} color={"info"} variant="gradient" size="sm" /></>,
                    value1: <>{items[0]}</>,
                    value2: <>{items[1]}</>,
                    value3: <>{items[2]}</>,
                    image: <><TextField
                      type="text"
                      value={items[3]}
                      onChange={(e) => handleChangeImage(index, e.target.value)}
                    /></>,
                    show: <><img src={items[3]} style={{ width: "50px" }}></img></>,
                    price: <><TextField
                      type="text"
                      value={items[4]}
                      onChange={(e) => handleChange(index, e.target.value)}
                    /></>,
                    gtin: <><TextField
                      type="text"
                      value={items[5]}
                      onChange={(e) => handleChangeGTIN(index, e.target.value)}
                    /></>,
                    mpn: <><TextField
                      type="text"
                      value={items[6]}
                      onChange={(e) => handleChangeMPN(index, e.target.value)}
                    /></>,
                    delete: <><MDBadge badgeContent="x" color="error" style={{ cursor: "pointer", margin: 2 }} onClick={() => handleDelete(index)} variant="gradient" size="sm" /></>,

                  })
                  )] : []
                }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              /> :
                <DataTable
                  table={{
                    columns: [
                      { Header: "id", accessor: "id", align: "center" },
                      { Header: `option 1`, accessor: "value1", align: "center" },
                      { Header: `Option 2`, accessor: "value2", align: "center" },
                      { Header: `Option 3`, accessor: "value3", align: "center" },
                      { Header: `image link`, accessor: "image", align: "center" },
                      { Header: `show`, accessor: "show", align: "center" },
                      { Header: "price", accessor: "price", align: "center" },
                      { Header: "gtin", accessor: "gtin", align: "center" },
                      { Header: "mpn", accessor: "mpn", align: "center" },
                      { Header: "delete", accessor: "delete", align: "center" },
                    ],
                    rows: manual && manual.length > 0 ? [...manual.map((items, index) => ({
                      id: <><MDBadge badgeContent={index + 1} color={"info"} variant="gradient" size="sm" /></>,
                      value1: <> {varName1 ?
                        <StyledFormControl sx={{ marginLeft: 1, marginRight: 1 }} >
                          <Select
                            sx={{ height: '45px', background: "linear-gradient(195deg, #EC407A, #D81B60)" }}
                            value={items?.[0]}
                            onChange={e => handleChangeManual1(index, e)}
                          >
                            <MenuItem value="any">{`Any ${varName1}`}</MenuItem>
                            {varValue1 && varValue1.split(",").length > 0 ? varValue1.split(",").map((items, index) => <MenuItem key={index} value={items}>{items}</MenuItem>) : null}
                          </Select>
                        </StyledFormControl> : null}
                      </>,
                      value2: <>{varName2 ?
                        <StyledFormControl sx={{ marginLeft: 1, marginRight: 1 }} >
                          <Select
                            sx={{ height: '45px', background: "linear-gradient(195deg, #EC407A, #D81B60)" }}
                            value={items?.[1]}
                            onChange={e => handleChangeManual2(index, e)}
                          >
                            <MenuItem value="any">{`Any ${varName2}`}</MenuItem>
                            {varValue1 && varValue1.split(",").length > 0 ? varValue1.split(",").map((items, index) => <MenuItem key={index} value={items}>{items}</MenuItem>) : null}
                          </Select>
                        </StyledFormControl> : null}
                      </>,
                      value3: <>{varName3 ?
                        <StyledFormControl sx={{ marginLeft: 1, marginRight: 1 }} >
                          <Select
                            sx={{ height: '45px', background: "linear-gradient(195deg, #EC407A, #D81B60)" }}
                            value={items?.[3]}
                            onChange={e => handleChangeManual3(index, e)}
                          >
                            <MenuItem value="any">{`Any ${varName3}`}</MenuItem>
                            {varValue1 && varValue1.split(",").length > 0 ? varValue1.split(",").map((items, index) => <MenuItem key={index} value={items}>{items}</MenuItem>) : null}
                          </Select>
                        </StyledFormControl> : null}
                      </>,
                      image: <><TextField
                        type="text"
                        value={items[3]}
                        onChange={(e) => handleChangeImageManual(index, e.target.value)}
                      /></>,
                      show: <><img src={items[3]} style={{ width: "50px" }}></img></>,
                      price: <><TextField
                        type="text"
                        value={items[4]}
                        onChange={(e) => handleChangeManual(index, e.target.value)}
                      /></>,
                      gtin: <><TextField
                        type="text"
                        value={items[5]}
                        onChange={(e) => handleChangeManualGTIN(index, e.target.value)}
                      /></>,
                      mpn: <><TextField
                        type="text"
                        value={items[6]}
                        onChange={(e) => handleChangeManualMPN(index, e.target.value)}
                      /></>,
                      delete: <><MDBadge badgeContent="x" color="error" style={{ cursor: "pointer", margin: 2 }} onClick={() => handleDeleteManual(index)} variant="gradient" size="sm" /></>,

                    })
                    )] : []
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              }

            </Box>
            : null}
          <MDButton variant="contained" color="info" component="span" sx={{ margin: 1 }} onClick={() => handleAdd()} >
            Thêm
          </MDButton>
        </Card>

        {!show ?
          <MDButton variant="contained" color="info" component="span" sx={{ margin: 1 }} onClick={() => setShow(!show)} >
            <MoreHorizIcon />
          </MDButton>
          : null}

        {show ? <Card style={{ textAlign: "center", marginTop: 10, padding: 5, alignItems: "center" }}>
          <DataTable
            table={{
              columns: [
                { Header: "stt", accessor: "stt", align: "left" },
                { Header: "sku", accessor: "sku", align: "left" },
                { Header: "title", accessor: "title", align: "left" },
                { Header: "price", accessor: "price", align: "left" },
                { Header: "category", accessor: "category", align: "left" },
                { Header: "tag", accessor: "tag", align: "left" },
                { Header: "brand", accessor: "brand", align: "left" },
                { Header: "size", accessor: "size", align: "left" },
                { Header: "color", accessor: "color", align: "left" },
                { Header: "material", accessor: "material", align: "left" },
                { Header: "type", accessor: "type", align: "left" },
                { Header: "delete", accessor: "delete", align: "left" },
              ],
              rows: data && data.length > 0 ? [...data.map((items, index) => ({
                stt: <><MDBadge badgeContent={items.index + 1} color="info" style={{ margin: 2 }} variant="gradient" size="lg" /></>,
                sku: <><MDBadge badgeContent={items.sku} color="info" style={{ margin: 2 }} variant="gradient" size="lg" /></>,
                title: <>{items.title}</>,
                price: <><MDBadge badgeContent={items.price} color="success" style={{ margin: 2 }} variant="gradient" size="lg" /></>,
                category: <>{items.category}</>,
                tag: <>{items.tag}</>,
                brand: <>{items.brand}</>,
                size: <>{items.size}</>,
                color: <>{items.color}</>,
                material: <>{items.material}</>,
                type: <><MDBadge badgeContent={items.type} color="dark" style={{ margin: 2 }} variant="gradient" size="lg" /></>,
                delete: <><MDBadge badgeContent="x" color="error" style={{ cursor: "pointer", margin: 2 }} onClick={() => handleDeleteData(items.id)} variant="gradient" size="lg" /></>,
              })
              )] : []
            }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
          <MDButton variant="contained" color="info" component="span" sx={{ margin: 1, width: 100 }} onClick={() => handleExport()} >
            Export
          </MDButton>
        </Card> : null}
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}

export default HandleList;
